<template>
	<div class="maintain">
	<tabbar :top1="top1" :top2="top2">
		<div class="titles1" style="margin-top: 10px;margin-right: 62%;">权限>管理员维护</div>
		<!-- <titles nameT="管理员维护"></titles> -->
		<!-- <search :flag='flag'></search> -->
		<contents :titles='listTitle' :flag='flag' :contentC='contentC' @del='refresh()'></contents>
	</tabbar>
	</div>
</template>

<script>
	import tabbar from '../public/Frame.vue'
	import titles from './comp/Title.vue';
	import contents from './comp/Content.vue';
	import search from './comp/Search.vue';
	export default{
		name:'maintain',
		data(){
			return{
				listTitle:null,
				flag: 1,
				contentC: null,
				top1: '3-1',
				top2: ['3'],
			}
		},
		components:{
			titles,
			contents,
			search,
			tabbar
		},
		created() {
			this.listTitle = ['管理员帐号','姓名','手机号','邮箱地址','添加时间','最后登录','是否开启','操作']
			let user = '';
			let page = 1;
			let limit = 10;
			let token = this.$storage.getLocal('token');
			this.$request.adminSel({token,user,page,limit}).then(res=>{
				if(res.code == 0){
					// console.log(res.data);
					this.contentC = res.data
				}
			})
		},
		methods:{
			refresh(obj){
				let user = '';
				let page = 1;
				let limit = 10;
				let token = this.$storage.getLocal('token');
				this.$request.adminSel({token,user,page,limit}).then(res=>{
					if(res.code == 0){
						// console.log(res.data);
						this.contentC = res.data
					}
				})
			}
		},
		computed:{
			
		},
	}
</script>

<style scoped="scoped">
	.maintain
	{
	background-color: rgb(245,247,249);
	height: 800px;
	}
</style>
