<template>
	<div class="">
		<div id="search" v-if="flag==1">
			<!-- <div class="search_title"> -->
				<!-- <div class="liubai">
					<div class="search_tex">搜索查询</div>
					<div class="search_ope">
						<div class="search_result">收齐筛选</div>
						<div class="search_result">查询结果</div>
					</div>
				</div> -->
		
			<!-- </div> -->
			<div class="search_inp">
				<div class="search_name">
					<div class="search_kkim">
						输入搜索：
					</div>
					<div class="search_kkim">
						<el-input v-model="input" placeholder="请输入内容"></el-input>
					</div>
				</div>
			</div>
		</div>
		<div id="search" v-if="flag==2">
			<div class="search_title">
				<div class="liubai">
					<div class="search_tex">搜索查询</div>
					<div class="search_ope">
						<div class="search_result">收齐筛选</div>
						<div class="search_result">查询结果</div>
					</div>
				</div>
		
			</div>
			<div class="search_inp">
				<div class="search_name">
					<div class="search_kkim">
						输入搜索：
					</div>
					<div class="search_kkim">
						<el-input v-model="input" placeholder="请输入内容"></el-input>
					</div>
				</div>
			</div>
		</div>
		<div id="search" v-if="flag==3">
			<div class="search_title">
				<div class="liubai">
					<div class="search_tex">搜索查询</div>
					<div class="search_ope">
						<div class="search_result">收齐筛选</div>
						<div class="search_result">查询结果</div>
					</div>
				</div>
			</div>
			<div class="search_inp">
				<div class="search_name">
					<div class="inpu">
						<div class="search_kkim kkim_text1">
							输入搜索：
						</div>
						<div class="search_kkim kkim_inp1">
							<el-input v-model="input3" placeholder="请输入内容"></el-input>
						</div>
					</div>
					<div class="inpu">
						<div class="search_kkim kkim_text">
							行业分类：
						</div>
						<div class="search_kkim kkim_inp">
							<el-input v-model="industry" placeholder="请输入内容"></el-input>
						</div>
					</div>
					<div class="inpu">
						<div class="search_kkim kkim_text">
							主营内幕：
						</div>
						<div class="search_kkim kkim_inp">
							<el-input v-model="inside" placeholder="请输入内容"></el-input>
						</div>
					</div>
					<div class="inpu">
						<div class="search_kkim kkim_text">
							状态：
						</div>
						<div class="search_kkim kkim_inp">
							<el-input v-model="state" placeholder="请输入内容"></el-input>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	
</template>

<script>
	export default {
		name: 'search',
		data() {
			return {
				input: '',
				input3: '',
				industry: '',
				inside: '',
				state: ''
			}
		},
		props: {
			nameT: {
				type: String,
			},
			flag: {
				type: Number
			}
		},
	}
</script>

<style scoped="scoped">
	#search {
		width: 95%;
		margin-left: 2.5%;
		font-size: 20px;
		/* border: 1px solid #333333; */		
	}

	.search_title {
		/* font-size: px; */
		height: 30px;
		font-size: 15px;
		width: 100%;
		/* margin: 0 auto; */
		border: 1px solid #E4E4E4;
		border-bottom: none;
		background: #F3F3F3;
	}

	.search_ope {
		display: flex;
	}

	.search_name {
		margin-top: 10px;
		display: flex;
		width: 86%;
		/* margin: 0 auto; */
		text-align: left;
		height: 50px;
		line-height: 50px;
		background-color: #FFFFFF;
		font-size: 15px;
		border: 1px solid #E4E4E4;
		margin-left: 14%;
	}

	.liubai {
		width: 95%;
		display: flex;
		justify-content: space-between;
		margin: 0 auto;
		line-height: 30px;
	}

	.search_kkim {
		margin-left: 15px;
		/* width: 50%; */
	}
	.inpu{
		display: flex;
		width: 20%;
	}
	.kkim_text{
		width: 40%;
	}
	.kkim_inp{
		width: 40%;
	}
	.kkim_text1{
		width: 40%;
	}
	.kkim_inp1{
		width: 60%;
	}
	/* .kkim_text{
		width: 50%;
	}
	.kkim_inp .el-input{
		width: 50%;
	} */
</style>



